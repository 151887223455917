/**
 * Controller for the off-boarding tool layout.
 *
 * Copyright (C) 2020A Noom, Inc.
 * @author nikola
 */

import { AxiosError } from "axios";
import { Api } from "@noom/noomscape";

import React from "react";
import { useSetState } from "react-use";

import {
  Logger,
  ToolState,
  useToolLog,
  getDefaultToolState,
} from "modules/tools";

import OffboardingLayout from "./OffboardingLayout";
import { OutputValue as FormOutputValue } from "./OffboardingForm";

const BATCH_SIZE = 10;

export type Result = {
  userAccessCode: string;
  userGroup?: { name: string };
};

export type Value = FormOutputValue;

export type State = ToolState<Value, Result[]> & {
  showConfirmationModal?: boolean;
};

const getDefaultState = () =>
  getDefaultToolState(
    {
      sourceUfc: "",
      targetUfcs: [],
      targetUsers: [],
      useTargetUsers: false,
    },
    []
  );

async function validateUFC(ufcAccessCode: string, log: Logger) {
  try {
    const ufcResponse = await Api.call("ufc.get", Api.api.ufc.get, {
      ufcAccessCode,
    });

    log(`UFC(${ufcAccessCode}) is a valid coach`);
    return ufcResponse.data;
  } catch (err) {
    log(`UFC(${ufcAccessCode}) is not a valid coach`, err as Error);
    throw err;
  }
}

async function listUsers(ufcAccessCode: string, limit: number, log: Logger) {
  try {
    const userResponse = await Api.call(
      "ufc.listUsers",
      Api.api.ufc.listUsers,
      { ufcAccessCode, limit }
    );

    log(`${userResponse.data.length} users fetched for UFC(${ufcAccessCode})`);
    return userResponse.data;
  } catch (err) {
    if ((err as AxiosError)?.response?.status === 400) {
      log(
        `Fetching users for UFC(${ufcAccessCode}) failed. Assuming Guide UFC. This will take a while and all users will be treated as active.`
      );
      try {
        const userResponse = await Api.call(
          "ufc.listUserAccessCodes",
          Api.api.ufc.listUserAccessCodes,
          { ufcAccessCode }
        );
        return userResponse.data;
      } catch (listError) {
        err = listError;
      }
      log(`Fetching users for UFC(${ufcAccessCode}) failed`, err as Error);
      throw err;
    }
  }
}

export async function moveUsers(
  oldCoachAccessCode: string,
  newCoachAccessCode: string,
  userAccessCodes: string[],
  log: Logger
) {
  try {
    await Api.call("ufc.massUpdate", Api.api.ufc.massUpdate, {
      oldCoachAccessCode,
      newCoachAccessCode,
      userAccessCodes,
    });

    log(`${userAccessCodes.length} users added to UFC(${newCoachAccessCode})`);
  } catch (err) {
    log(
      `Failed adding ${userAccessCodes.length} users to UFC(${newCoachAccessCode})`,
      err as Error
    );
    throw err;
  }
}

/**
 * User access codes are sent as URL query parameters
 *  We need to batch them because of the URL size limits
 */
export async function batchMoveUsers(
  sourceUfc: string,
  targetUfc: string,
  userAccessCodes: string[],
  log: Logger
) {
  let userSuccessCount = 0;
  let userFailCount = 0;

  for (let index = 0; index < userAccessCodes.length; index += BATCH_SIZE) {
    const batch = userAccessCodes.slice(index, index + BATCH_SIZE);

    if (batch.length) {
      try {
        await moveUsers(sourceUfc, targetUfc, batch, log);
        userSuccessCount += batch.length;
      } catch (e) {
        userFailCount += batch.length;
      }
    }
  }

  return [userSuccessCount, userFailCount] as const;
}

function OffboardingController() {
  const [state, setState] = useSetState<State>(getDefaultState());
  const [log, updateLog, clearLog] = useToolLog();

  const onStart = async () => {
    setState({
      showConfirmationModal: false,
      inProgress: true,
      showReport: false,
    });
    const { sourceUfc, targetUfcs, useTargetUsers, targetUsers } = state?.value;

    let userSuccessCount = 0;
    let userFailCount = 0;

    try {
      await validateUFC(sourceUfc, updateLog);

      for (const targetUfc of targetUfcs) {
        await validateUFC(targetUfc, updateLog);
      }

      if (useTargetUsers) {
        const numOfUsersPerCoach = Math.ceil(
          targetUsers.length / targetUfcs.length
        );

        for (const targetUfcIndex in targetUfcs) {
          const targetUfc = targetUfcs[targetUfcIndex];

          const userAccessCodes = getSlice(
            Number(targetUfcIndex),
            numOfUsersPerCoach,
            targetUsers
          );

          const [batchSuccess, batchFail] = await batchMoveUsers(
            sourceUfc,
            targetUfc,
            userAccessCodes,
            updateLog
          );
          userSuccessCount += batchSuccess;
          userFailCount += batchFail;
        }

        updateLog(`Offboarded ${targetUsers.length} users`);
      } else {
        while (true) {
          const users = await listUsers(sourceUfc, 1000, updateLog);
          if (users.length === 0) {
            break;
          }

          const activeUsers = users.filter((u) => u.isActive);
          const inactiveUsers = users.filter((u) => !u.isActive);

          const numOfActiveUsersPerCoach = Math.ceil(
            activeUsers.length / targetUfcs.length
          );
          const numOfInactiveUsersPerCoach = Math.ceil(
            inactiveUsers.length / targetUfcs.length
          );

          for (const targetUfcIndex in targetUfcs) {
            const targetUfc = targetUfcs[targetUfcIndex];

            const activeUserAccessCodes = getUserSliceAccessCodes(
              Number(targetUfcIndex),
              numOfActiveUsersPerCoach,
              activeUsers
            );

            const inactiveUserAccessCodes = getUserSliceAccessCodes(
              Number(targetUfcIndex),
              numOfInactiveUsersPerCoach,
              inactiveUsers
            );

            const userAccessCodes = [
              ...activeUserAccessCodes,
              ...inactiveUserAccessCodes,
            ];

            const [batchSuccess, batchFail] = await batchMoveUsers(
              sourceUfc,
              targetUfc,
              userAccessCodes,
              updateLog
            );
            userSuccessCount += batchSuccess;
            userFailCount += batchFail;
          }

          updateLog(`Offboarded ${users.length} users`);
        }
      }

      updateLog("DONE!");
      updateLog("------------------------------------------");
      updateLog("REPORT:");
      updateLog(
        `Users transfered successfully: ${userSuccessCount}/${
          userSuccessCount + userFailCount
        }`,
        userFailCount > 0
      );
    } catch (e) {}

    setState({ inProgress: false, showReport: true });
  };

  const onCancel = () => {
    setState({ showConfirmationModal: false });
  };

  const onSubmit = (value: Value) => {
    clearLog();
    setState({
      showConfirmationModal: true,
      value,
    });
  };

  const onRetryAll = () => {
    const { value } = state;
    setState({ ...getDefaultState(), value });
  };

  const onReset = () => {
    setState(getDefaultState());
  };

  const { failed, showReport, inProgress, value, showConfirmationModal } =
    state;

  return (
    <OffboardingLayout
      log={log}
      failed={failed}
      showReport={showReport}
      inProgress={inProgress}
      value={value}
      showConfirmationModal={showConfirmationModal}
      onStart={onStart}
      onCancel={onCancel}
      onSubmit={onSubmit}
      onRetryAll={onRetryAll}
      onReset={onReset}
    />
  );
}

function getSlice<T>(index: number, sliceSize: number, list: T[]) {
  return list.slice(index * sliceSize, index * sliceSize + sliceSize);
}

function getUserSliceAccessCodes(
  targetUfcIndex: number,
  numOfUsersPerCoach: number,
  userList: { accessCode: string }[]
) {
  return getSlice(targetUfcIndex, numOfUsersPerCoach, userList).map(
    (u) => u.accessCode
  );
}

export default OffboardingController;
